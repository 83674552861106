import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react"
import { graphql, Link } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"
import Div100vh from "react-div-100vh"
import PubSub from "pubsub-js"
// import {throttle, debounce} from 'throttle-debounce'
//import cookie from 'react-cookies'
// import { linkResolver } from "../core/utils"
import SEO from "../components/seo"
import { WrapperContext } from "../components/Layout"
// import { RichText } from "prismic-reactjs"
import SlicesScroller from "../components/slices/SlicesScroller"
// import {useEventListener} from "../core/utils"
// import CardHome from "../components/CardHome"
const Logo = require("../images/asa-logo.inline.svg")

export const pageQuery = graphql`
  query($lang: String!) {
    prismicHome(lang: { eq: $lang }) {
      type
      data {
        ...home
      }
    }
  }
`

const IS_BROWSER = typeof window !== "undefined"

const Home = ({ path, pageContext: { alternates }, data: staticData }) => {
  // console.log(alternates)
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    })
  }, [staticData])

  // if (!home) return null
  const _WrapperContext = useContext(WrapperContext)
  const { settings } = _WrapperContext
  console.log(settings)
  const { title, texte, body } = data.prismicHome.data
  const logoRef = useRef()
  let logoBounding

  useEffect(() => {
    PubSub.publish("PROJECT_TEXTE", null)
    // const tokenS = PubSub.subscribe("SCROLL", _onScroll)
    setTimeout(() => _onResize(), 250)
    // const _onMouseMoveThrottle = throttle(250, _onMouseMove);
    window.addEventListener("resize", _onResize)
    document.body.addEventListener("mousemove", _mouseMoveHandler)

    return () => {
      // PubSub.unsubscribe(tokenS)
      document.body.removeEventListener("mousemove", _mouseMoveHandler)
      window.removeEventListener("resize", _onResize)
      // console.log("------------- left")
    }
  }, [])

  const _onResize = () => {
    console.log("_onResize _onResize")
    logoBounding = logoRef.current.getBoundingClientRect()
  }

  const _mouseMoveHandler = useCallback(({ pageX, pageY }) => {
    if (!logoBounding) _onResize()
    // throttle(250, _onMouseMove)
    // console.log(pageX, pageY, logoBounding.top)
    if (
      pageX > logoBounding.left &&
      pageX < logoBounding.right &&
      pageY > logoBounding.top &&
      pageY < logoBounding.bottom
    ) {
      document.body.classList.add("is-dark")
    } else {
      document.body.classList.remove("is-dark")
    }
  }, [])

  // Add event listener using our hook
  // useEventListener('mousemove', _mouseMoveHandler);
  // const _onScroll = (e, d) => {
  //   // console.log(d.x)
  //   // if(d.x !== 0){
  //   //   logoRef.current.classList.add("slideLeft")
  //   // }else{
  //   //   logoRef.current.classList.remove("slideLeft")
  //   // }
  // }

  // const _onMouseMove = (e) => {
  //   console.log(e.pageX, e.pageY, logoBounding)
  //   // if(e.pageX > logoBounding.left
  //   //   && e.pageX < logoBounding.right
  //   //   && e.pageY > logoBounding.top
  //   //   && e.pageY < logoBounding.bottom){
  //   //   document.body.classList.add("is-dark")
  //   // }else{
  //   //   document.body.classList.remove("is-dark")
  //   // }
  // }

  // const _onMouseEnter = (e) => {
  //   console.log(e)
  //   document.body.classList.add("is-dark")
  // }

  // const _onMouseLeave = (e) => {
  //   document.body.classList.remove("is-dark")
  // }

  return (
    <div className="page-template home">
      <SEO
        pageTitle={title.text}
        pageDescription={texte.text}
        pageImage={settings.image.url}
        template="template-home"
        alternates={alternates}
        pathname={path}
      />
      <Div100vh>
        {body && <SlicesScroller input={body} context="scroll" />}

        <div
          className="asa-logo"
          ref={logoRef}
          // onMouseEnter={_onMouseEnter}
          // onMouseLeave={_onMouseLeave}
        >
          <Logo />
        </div>
      </Div100vh>
    </div>
  )
}

export default Home
